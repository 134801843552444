// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$pwc-ui-primary: mat.define-palette(mat.$indigo-palette);
$pwc-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$pwc-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$pwc-ui-theme: mat.define-light-theme(
  (
    color: (
      primary: $pwc-ui-primary,
      accent: $pwc-ui-accent,
      warn: $pwc-ui-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($pwc-ui-theme);

/* You can add global styles to this file, and also import other style files */
@import "scss/main";
@import "./assets/css/bootstraplayout.css";
@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk&display=swap");
html,
body {
  height: 100%;
  background: #f4f4f8;
}
body,
h3 {
  margin: 0;
  font-family: "Hanken Grotesk", sans-serif !important;
}

a {
  text-decoration: none;
  display: inline-block;
  padding: 16px 24px;
  border-radius: 5px;
}

// input form field styling
.mat-form-field-appearance-outline .mat-form-field-outline {
  border-radius: 12px !important;
}
.mat-form-field-outline-start {
  border-radius: 12px 0 0 12px !important;
  min-width: 12px !important;
}

.mat-form-field-outline-end {
  border-radius: 0 12px 12px 0 !important;
}

.input-container {
  display: flex;
  flex-direction: column;
}
mat-label {
  margin-left: 0.3em;
  font-size: 16px;
  font-weight: 600 !important;
  font-family: "Hanken Grotesk", sans-serif !important;
  color: #0c0209;
}

/* dropdown styling */
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #f4f4f8 !important;
}

.topunit.mat-select-panel {
  background-color: #f4f4f8 !important;
}

.mat-select-content {
  background-color: #f4f4f8 !important;
}

.cdk-overlay-container .cdk-overlay-pane .testClass {
  margin-top: 20%;
}

.mat-dialog-title {
  font-family: "Hanken Grotesk", sans-serif !important;
}

/* title styling */

.mat-grid-tile-content {
  display: flex;
  flex-direction: column !important;
  align-items: start !important;
  justify-content: center;
}

.mat-grid-list {
  padding-bottom: 0 !important;
}

.title {
  font-size: 32px;
  font-weight: 700;
  font-family: "Hanken Grotesk", sans-serif !important;
  list-style: 24px;
}

.heading {
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-family: "Hanken Grotesk", sans-serif !important;
  list-style: 38px;
}

.mat-list-item-content {
  flex-direction: row-reverse !important;
}

.mat-progress-bar,
.mat-progress-bar-fill::after {
  background: #272627 !important;
}

//Keys
.keys {
  // width: 5.5%;
  height: 5%;
  cursor: pointer;
}

.key-text {
  bottom: 10px;
  position: relative;
}

.core {
  background-color: #51d9f5 !important;
  text-align: center;
}

.non-value {
  background-color: #d24833 !important;
  color: white !important;
  text-align: center;
}

.value {
  background-color: #6bc4a8 !important;
  text-align: center;
}

.tle {
  font-size: 16px;
  font-weight: 600;
  position: relative;
  top: 4px;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #3f3d3e !important;
}

.mat-progress-bar-buffer {
  background-color: #ffffff !important;
}

//

.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: #272627 !important;
}
.mat-form-field-outline {
  background-color: #f4f4f8 !important;
}

//
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #1f1d20 #eae6e6;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 14px;
}

*::-webkit-scrollbar-track {
  background: #f4f4f8;
}

*::-webkit-scrollbar-thumb {
  background-color: #1f1d20;
  border-radius: 6px;
  border: 4.5px solid #ffffff;
}

//

.select-all {
  margin: 14px 17px;
}

// text-breal

.ellipsis {
  text-overflow: ellipsis; /* enables ellipsis */
  white-space: nowrap; /* keeps the text in a single line */
  overflow: hidden; /* keeps the element from overflowing its parent */
  max-width: 200px;
}

//Average Chart
.avg-chart-content {
  position: absolute;
  padding-left: 6.5em;
  padding-top: 1em;
  font-size: 18px;
}

/*mat-tab*/
.invisible-tabs {
  > .mat-tab-header {
    display: none !important;
  }
}

.mat-tab-label {
  font-size: 17.5px !important;
}

.mat-tab-group {
  font-family: "Hanken Grotesk", sans-serif !important;
}

.mat-tab-label.mat-tab-label-active {
  border-radius: 0.75em !important;
}

/* table styling */

.mat-table {
  font-family: "Hanken Grotesk", sans-serif !important;
  border-radius: 10px;
  overflow: hidden !important;
}

td.mat-cell {
  font-size: 16px;
  font-weight: 300;
  color: black;
  border-bottom-style: none !important;
}

th.mat-header-cell {
  font-size: 16px;
  font-weight: 600;
  color: black;
  border-bottom-color: #ffffff;
}

.mat-dialog-container {
  border-radius: 1em !important;
}

/* arrow buttons */

.arr-buttons {
  padding-left: 0.75em;
}

.previous:hover,
.next:hover {
  background-color: #ddd;
  color: black;
}

.previous,
.next {
  background-color: #fafafe;
  color: black;
  text-decoration: none;
  display: inline-block;
  padding: 10px 14px;
  border-radius: 5px;
  font-size: 22px;
}

/* stepper */
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: rgba(0, 0, 0, 0.87);
  color: white;
}

.mat-step-icon-state-done {
  background-color: rgb(39 177 49 / 87%) !important;
}

.mat-horizontal-content-container {
  overflow: hidden;
  padding: 0em !important;
}

$color_3: #ffffff;
$background-color_2: #000000;
.add-survey-btn {
  &:hover {
    color: $color_3;
    background-color: $background-color_2;
    border-radius: 6px;
  }
}

// .ngx-mat-timeline-item-icon{
//   color: gold !important;
// }

//Survey Page LOGO

.lg {
  display: flex;
  justify-content: center;
  background-color: #fafafe;
}

.lg-space {
  margin-top: 1em;
}

//Survey Page LOGO ends

//for spinner
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 15em;
  right: 0;
  z-index: 2;
  backdrop-filter: blur(5px);
}

.report-container {
  height: 60vh;
  margin: 8px auto;
}

//timeline starts here

.labels {
  width: 85%;
  display: flex;
  // margin-left: -2rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  p {
    max-width: 40px;
  }
}
.labels2 {
  width: 98%;
  display: flex;
  // margin-left: -2rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  p {
    max-width: 40px;
  }
}
.hr-theme-slash-1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  .hr-line {
    border-bottom: 1px solid #000;
    display: flex;
    flex-direction: row;
  }
  .hr-icon {
    position: relative;
    top: 10px;
    color: #dbb0f5;
    display: flex;
    flex-direction: row;
    /* background-color: #ECDCF5; */
    border-radius: 1rem;
    margin-right: 19%;
  }
}
.hr-theme-slash-2 {
  display: flex;
  flex-direction: column;
  width: 80%;
  .hr-line {
    width: 100%;
    position: relative;
    margin: 15px;
    border-bottom: 1px solid #000;
    display: flex;
    flex-direction: row;
  }
  .hr-icon {
    position: relative;
    top: 10px;
    color: #dbb0f5 !important;
    display: flex;
    margin-left: -1rem;
    margin-right: 7rem;
    flex-direction: row;
    /* background-color: #ECDCF5; */
    border-radius: 1rem;
  }
}

.timeline-container1 {
  left: 2.5rem;
  bottom: 50%;
  position: relative;
  width: 78%;
}
.timeline-container2 {
  left: 2.5rem;
  bottom: 50%;
  position: relative;
}

.average1 {
  color: black;
  text-align: center;
  position: relative;
  margin-top: -3%;
}

.val {
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

.circleStyles {
  position: absolute;
  width: 25px; /* Set the width and height to create a circle */
  height: 25px;
  border-radius: 50%; /* Use border-radius to create a circle */
  background-color: rgb(200, 200, 238); /* Set the fill color */
  border: 2px solid black; /* Set the border color and width */
  align-items: center;
  justify-content: center;
  text-align: center;
  //   margin: 20px; /* Adjust margin as needed */
}

.c_fix{
  left: 0px;
}

.rating-line {
  position: relative;
  display: flex;
  border-bottom: 2px solid rgb(201, 170, 170); /* Adjust the thickness and color as needed */
  width: 25rem; /* Adjust the width of the line */
  height: 0; /* Set the height to 0 to create a horizontal line */
  margin: 30px;
  padding: 10px;
}

.rating-line2 {
  position: relative;
  display: flex;
  border-bottom: 2px solid rgb(201, 170, 170); /* Adjust the thickness and color as needed */
  width: 90%; /* Adjust the width of the line */
  height: 0; /* Set the height to 0 to create a horizontal line */
  margin: 30px;
  padding: 10px;
}

.circle:hover {
  cursor: pointer; /* Change cursor to pointer on hover */
}

.average {
  ::ng-deep .ngx-mat-timeline-item-icon {
    color: black !important;
    font-size: 2em;
    position: absolute;
    margin-bottom: 8px;
  }
  // text-align: center;
  // position: relative;
  // margin-left: 10px;
}

.mi {
  margin-left: -4px;
}

//timeline ends here

//timeline-related styling

tr.mat-row {
  height: 50px !important;
  align-items: center;
  text-align: start;
  cursor: pointer;
}

.ngx-mat-timeline {
  margin-left: -8em;
  overflow: hidden;
  margin-top: 2em;
}

::ng-deep .ngx-mat-timeline-item-icon {
  color: #dbb0f5 !important;
}

::ng-deep .ngx-mat-timeline-item-icon:hover {
  color: gold;
  transition: 0.3ms;
}

.highlighted {
  background-color: aliceblue;
}

.selected-icon {
  // border: 2px solid red; /* Adjust border style as needed */
  // border-bottom: 1px solid red;
  ::ng-deep .ngx-mat-timeline-item-icon {
    color: gold !important;
  }
}

@import "scss/main";
