.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background-color: transparent;
}

.mat-tab-label-container{
    padding: 10px;
}

.mat-tab-header {
    border-bottom: 0 solid;
}

.mat-tab-label {
    background-color: transparent;
    color: #0c0209;
    font-weight: 400;
    line-height: 24px;
    font-style: normal;
    font-size: 20px;
    border-radius: 5px;
    border-bottom: 0 solid;
    min-width: 100%;
    font-family: 'Hanken Grotesk', sans-serif !important;
}

.mat-tab-label.mat-tab-label-active {
    background-color: #0c0209;
    color: #f4f4f8;
    opacity: 1;
}

.mat-tab-header {
    background: #ffffff;
    border-radius: 8px;
}
