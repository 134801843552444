/* Active Stroke Large */

.active-stroke-lrg {
    background-color: #FFFFFF;
    border: 1px solid #222222;
    border-radius: 8px;
    box-sizing: border-box;
    color: #222222;
    cursor: pointer;
    display: inline-block;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 18px;
    font-weight: 600;
    // line-height: 20px;
    margin: 0;
    outline: none;
    padding: 13px 27px;
    position: relative;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
    user-select: none;
    -webkit-user-select: none;
    width: 20rem;
    // width: auto;
  }
  
  .active-stroke-lrg :focus-visible {
    box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
    transition: box-shadow .2s;
  }
  
  .active-stroke-lrg:active {
    background-color: #F7F7F7;
    border-color: #000000;
    transform: scale(.96);
  }
  
  .active-stroke-lrg:disabled {
    border-color: #DDDDDD;
    color: #DDDDDD;
    cursor: not-allowed;
    opacity: 1;
  }
  
  /* Active Solid Large */
  
  .active-solid-lrg {
    background-color: #222222;
    border: 1px solid #FFFFFF;
    border-radius: 10px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 16px;
    font-weight: 400;
    // line-height: 20px;
    margin: 0;
    outline: none;
    padding: 13px 27px;
    position: relative;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
    user-select: none;
    -webkit-user-select: none;
    width: 20rem;
    // width: auto;
  }
  
  .active-solid-lrg :focus-visible {
    box-shadow: #222222 0 0 0 2px, rgba(14, 13, 13, 0.8) 0 0 0 4px;
    transition: box-shadow .2s;
  }
  
  .active-solid-lrg:active {
    background-color: #222222;
    border-color: #000000;
    transform: scale(.96);
  }
  
  .active-solid-lrg:disabled {
    border-color: #222222;
    color: #222222;
    cursor: not-allowed;
    opacity: 1;
  }
  
  /* Active Solid Med */
  
  .active-solid-med {
    background-color: #222222;
    border: 1px solid #FFFFFF;
    border-radius: 7px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 14px;
    font-weight: 600;
    // line-height: 20px;
    margin: 0;
    outline: none;
    padding: 9px 13px;
    position: relative;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
    user-select: none;
    -webkit-user-select: none;
    width: auto;
  }
  
  .active-solid-med :focus-visible {
    box-shadow: #222222 0 0 0 2px, rgba(14, 13, 13, 0.8) 0 0 0 4px;
    transition: box-shadow .2s;
  }
  
  .active-solid-med:active {
    background-color: #222222;
    border-color: #000000;
    transform: scale(.96);
  }
  
  .active-solid-med:disabled {
    border-color: #222222;
    color: #222222;
    cursor: not-allowed;
    opacity: 1;
  }
  
  /* Active Stroke Sml */
  
  .active-stroke-sml {
    background-color: #FFFFFF;
    border: 1px solid #222222;
    border-radius: 4px;
    box-sizing: border-box;
    color: #222222;
    cursor: pointer;
    display: inline-block;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 14px;
    // font-weight: 600;
    // line-height: 20px;
    margin: 0;
    outline: none;
    padding: 5px 4px;
    position: relative;
    text-align: center;
    text-decoration: none;
    touch-action: manipulation;
    transition: box-shadow .2s,-ms-transform .1s,-webkit-transform .1s,transform .1s;
    user-select: none;
    -webkit-user-select: none;
    // width: auto;
    width: 4rem;
  }
  
  .active-stroke-sml :focus-visible {
    box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
    transition: box-shadow .2s;
  }
  
  .active-stroke-sml:active {
    background-color: #F7F7F7;
    border-color: #000000;
    transform: scale(.96);
  }
  
  .active-stroke-sml:disabled {
    border-color: #DDDDDD;
    color: #DDDDDD;
    cursor: not-allowed;
    opacity: 1;
  }
  